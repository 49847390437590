<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'stock-wholesale' }">{{
            $t("STOCK_WHOLESALE")
          }}</router-link>
        </h3>
      </template>
      <template v-slot:body>
        <b-form>
          <StockWholesaleStepper
            v-show="!firstLoader"
            :firstLoader.sync="firstLoader"
          >
          </StockWholesaleStepper>
          <SkeletonLoaderMain :visible="firstLoader"></SkeletonLoaderMain>
          <v-overlay
            :value="
              !firstLoader &&
              (isLoadingStock == true || isLoadingSharedStore == true)
            "
          >
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import StockWholesaleStepper from "@/modules/stock/components/StockWholesaleStepper.vue";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [],
  data() {
    return {
      errors: {},
      firstLoader: true
    };
  },
  components: {
    KTCard,
    StockWholesaleStepper
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stock wholesale", route: { name: "stock-wholesale" } }
    ]);
  },
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: (state) => state.auth.errors
    })
  },
  methods: {}
};
</script>
