<template>
  <v-stepper v-model="actionParams.wizardStep">
    <v-stepper-header>
      <v-stepper-step :complete="actionParams.wizardStep > 1" step="1">
        {{ $t("STEP") }} 1
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="actionParams.wizardStep > 2" step="2">
        {{ $t("STEP") }} 2
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="actionParams.wizardStep > 3" step="3">
        {{ $t("STEP") }} 3
      </v-stepper-step>
      <v-divider></v-divider>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <StepStockActionSelectClientClientStore
          :firstLoader.sync="tempFirstLoader"
          :doGetItems.sync="doGetItems"
          :wizardStep.sync="actionParams.wizardStep"
          :items.sync="items"
          :selectedStore.sync="selectedStore"
          :selectedClient.sync="selectedClient"
          :selectedClientStore.sync="selectedClientStore"
          @updateWholesaleClientId="globalWholesaleDoc.clientId = $event"
          @updateWholesaleClientStoreId="
            globalWholesaleDoc.clientStoreId = $event
          "
          @updateWholesaleCurrencyId="globalWholesaleDoc.currencyId = $event"
          @updateWholesaleVatGroupId="globalWholesaleDoc.vatGroupId = $event"
        >
        </StepStockActionSelectClientClientStore>
      </v-stepper-content>
      <v-stepper-content step="2">
        <StepStockActionSetItems
          :firstLoader.sync="tempFirstLoader"
          :wizardStep.sync="actionParams.wizardStep"
          :globalWholesaleDoc.sync="globalWholesaleDoc"
          :globalSupplierDoc.sync="globalSupplierDoc"
          :globalTransportDoc.sync="globalTransportDoc"
          :globalAddonDoc.sync="globalAddonDoc"
          :globalPalletDoc.sync="globalPalletDoc"
          :globalCustomsDoc.sync="globalCustomsDoc"
          :notes.sync="notes"
          :items.sync="items"
          :headers="dynamicHeaders"
          :selectedItems="selectedItems"
          :selectedItemsTotal="selectedItemsTotal"
          :selectedItemsTotalWholesale="selectedItemsTotalWholesale"
          :store="selectedStore"
          :client="selectedClient"
          :clientStore="selectedClientStore"
          :stockActionTypeId="stockActionTypeId"
          :forwarders="forwarders"
          :contractors="contractors"
          :customs="customs"
          :currencies="currencies"
          :vatGroups="vatGroups"
          :showTotalPriceWholesale="true"
        >
        </StepStockActionSetItems>
      </v-stepper-content>
      <v-stepper-content step="3">
        <StepStockActionPreview
          :firstLoader.sync="tempFirstLoader"
          :doGetItems.sync="doGetItems"
          :wizardStep.sync="actionParams.wizardStep"
          :globalWholesaleDoc.sync="globalWholesaleDoc"
          :globalSupplierDoc.sync="globalSupplierDoc"
          :globalTransportDoc.sync="globalTransportDoc"
          :globalAddonDoc.sync="globalAddonDoc"
          :globalPalletDoc.sync="globalPalletDoc"
          :globalCustomsDoc.sync="globalCustomsDoc"
          :notes.sync="notes"
          :selectedItems="selectedItems"
          :headers="dynamicHeaders"
          :selectedItemsTotal="selectedItemsTotal"
          :selectedItemsTotalWholesale="selectedItemsTotalWholesale"
          :selectedStore.sync="selectedStore"
          :client="selectedClient"
          :clientStore="selectedClientStore"
          :stockActionTypeId="stockActionTypeId"
          :forwarders="forwarders"
          :contractors="contractors"
          :customs="customs"
          :currencies="currencies"
          :vatGroups="vatGroups"
          :showTotalPriceWholesale="true"
        >
        </StepStockActionPreview>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import StepStockActionSelectClientClientStore from "@/modules/stock/components/actions/stockReceiving/StepStockActionSelectClientClientStore.vue";
import StepStockActionSetItems from "@/modules/stock/components/actions/StepStockActionSetItems.vue";
import StepStockActionPreview from "@/modules/stock/components/actions/StepStockActionPreview.vue";
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";

import _ from "lodash";
export default {
  name: "StockWholesaleStepper",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      stockActionTypeId: 11,
      doGetItems: true,
      actionParams: {
        wizardStep: 1
      },
      globalWholesaleDoc: {
        visible: true,
        num: "",
        date: "",
        vatGroupId: 1,
        currencyId: 1,
        clientId: null,
        clientStoreId: null,
        totalCurrency: "0.00",
        totalCurrencyWholesale: "0.00"
      },
      globalSupplierDoc: {
        visible: false,
        num: "",
        date: "",
        supplierId: null,
        vatGroupId: 3,
        currencyId: 1,
        totalCurrency: "0.00"
      },
      globalTransportDoc: {
        visible: false,
        num: "",
        date: "",
        forwarderId: 1,
        vatGroupId: 1,
        currencyId: 1,
        totalCurrency: "0.00"
      },
      globalAddonDoc: {
        visible: false,
        num: "",
        date: "",
        contractorId: 1,
        vatGroupId: 1,
        currencyId: 1,
        totalCurrency: "0.00"
      },
      globalPalletDoc: {
        visible: false,
        num: "",
        date: "",
        vatGroupId: 1,
        currencyId: 1,
        totalCurrency: "0.00"
      },
      globalCustomsDoc: {
        visible: false,
        num: "",
        date: "",
        customsId: 1,
        vatGroupId: 1,
        currencyId: 1,
        totalCurrency: "0.00"
      },
      notes: "",
      items: [],
      selectedStore: {},
      selectedClient: {},
      selectedClientStore: {},
      customs: {},
      currencies: {},
      vatGroups: {},
      startHeaders: [
        {
          text: this.$t("ITEM"),
          align: "start",
          filterable: true,
          value: "name"
        },
        {
          text: this.$t("SINGLE_PRICE"),
          value: "stockActionItem.singlePrice",
          sortable: true
        },
        {
          text: this.$t("TOTAL_PRICE"),
          value: "stockActionItem.totalPrice",
          sortable: true
        },
        {
          text: this.$t("WHOLESALE"),
          value: "stockActionItem.unitValue",
          sortable: true
        },
        {
          text: this.$t("SINGLE_PRICE_WHOLESALE"),
          value: "wholesale.singlePrice",
          sortable: true
        },
        {
          text: this.$t("TOTAL_PRICE_WHOLESALE"),
          value: "wholesale.totalPrice",
          sortable: true
        }
      ],
      endHeaders: [
        {
          text: this.$t("QUANTITY"),
          value: "stockActionItem.sumLastStockActionsUnitValue",
          sortable: true
        },
        {
          text: this.$t("LOT"),
          value: "stockActionItem.stockLot",
          sortable: true
        },
        {
          text: this.$t("LOT_EXP_DATE"),
          value: "stockActionItem.stockLotExpDate",
          sortable: true
        }
      ]
    };
  },
  components: {
    StepStockActionSelectClientClientStore,
    StepStockActionSetItems,
    StepStockActionPreview
  },
  mounted() {
    let vm = this;
    vm.getSelects();
  },
  computed: {
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    selectedItems() {
      let vm = this;
      let selectedItems = [];
      if (_.size(vm.items) > 0) {
        vm.items.forEach(category => {
          let filteredItems = category.items.filter(function(item) {
            return item.stockActionItem.unitValue > 0;
          });
          selectedItems = [...selectedItems, ...filteredItems];
        });
      }
      return selectedItems;
    },
    selectedItemsTotal() {
      let vm = this;
      let totalPrice = 0;
      vm.selectedItems.forEach(item => {
        let itemTotalPrice = item.stockActionItem.totalPrice;
        totalPrice += parseFloat(itemTotalPrice);
      });
      return this.$options.filters.formatBalance(totalPrice);
    },
    selectedItemsTotalWholesale() {
      let vm = this;
      let totalPrice = 0;
      vm.selectedItems.forEach(item => {
        let itemTotalPrice = item.wholesale.totalPrice;
        totalPrice += parseFloat(itemTotalPrice);
      });
      return this.$options.filters.formatBalance(totalPrice);
    },
    dynamicHeaders() {
      let vm = this;
      let dynamicHeaders = vm.startHeaders;

      if (vm.globalCustomsDoc.visible == true) {
        let customsHeader = [
          {
            text: this.$t("CUSTOMS_PRICE"),
            value: "stockActionItem.customsTotalFee",
            sortable: true
          }
        ];

        dynamicHeaders = [...dynamicHeaders, ...customsHeader];
      }

      dynamicHeaders = [...dynamicHeaders, ...vm.endHeaders];
      return dynamicHeaders;
    }
  },
  watch: {
    selectedItems: {
      handler: function(newValue, oldValue) {
        this.globalCustomsDoc.totalCurrency = newValue
          .reduce(
            (sum, item) =>
              sum + parseFloat(item.stockActionItem.customsTotalFee),
            0
          )
          .toFixed(6);

        this.globalSupplierDoc.totalCurrency = newValue
          .reduce(
            (sum, item) => sum + parseFloat(item.stockActionItem.totalPrice),
            0
          )
          .toFixed(6);

        this.globalWholesaleDoc.totalCurrency = newValue
          .reduce(
            (sum, item) => sum + parseFloat(item.stockActionItem.totalPrice),
            0
          )
          .toFixed(6);

        this.globalWholesaleDoc.totalCurrencyWholesale = newValue
          .reduce((sum, item) => sum + parseFloat(item.wholesale.totalPrice), 0)
          .toFixed(6);
      },
      deep: true
    }
  },
  methods: {
    getSelects() {
      let vm = this;
      let payload = {
        forwarders: {
          fields: ["id", "name"],
          search: ""
        },
        contractors: {
          fields: ["id", "name"],
          search: ""
        },
        customs: {
          fields: ["id", "name"],
          search: ""
        },
        currencies: {
          fields: ["id", "name", "code"],
          search: ""
        },
        vat_groups: {
          fields: ["id", "name", "percent"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.forwarders = data.data.forwarders;
          vm.contractors = data.data.contractors;
          vm.customs = data.data.customs;
          vm.currencies = data.data.currencies;
          vm.vatGroups = data.data.vat_groups;
          vm.globalSupplierDoc.currencyId = vm.currencies[0].id;
          vm.globalSupplierDoc.vatGroupId = vm.vatGroups[0].id;
        })
        .catch(response => {
          console.log(response);
        });
    },
    updateWizardStep(step) {
      let vm = this;
      vm.actionParams.wizardStep = step;
    }
  }
};
</script>
